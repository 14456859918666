import TF from "../../../components/forms/TextField.module.css";
import C from "./DebugMenu.module.css";

import { useAtom, useAtomValue, useSetAtom } from "jotai/react";
import { atom } from "jotai/vanilla";
import stringify from "json-stringify-pretty-compact";
import { Suspense, useMemo, useState, type FormEvent, type PropsWithChildren, type ReactNode } from "react";
import { Input, Label, NumberField, TextArea, TextField } from "react-aria-components";
import { entries } from "remeda";
import { availableLocales } from "../../../availableLocales.ts";
import { WButton } from "../../../components/button/WButton.tsx";
import { WTooltipButton } from "../../../components/button/WToolButton.tsx";
import { SimpleSwitch } from "../../../components/dev/SimpleSwitch.tsx";
import { WSimpleSelect, toSimpleItem } from "../../../components/dev/WSimpleSelect.tsx";
import { FieldButtonGroup } from "../../../components/forms/FieldButtonGroup.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { allMockConfigs, mockProviderAtom, type MockProvider } from "../../../config.mock.ts";
import {
    resetConfigOverridesAtom,
    resetConfigOverridesTitle,
    updateConfigOverrideAtom,
} from "../../../config.override.ts";
import { useConfig } from "../../../config.ts";
import type { SiteConfig } from "../../../config.types.ts";
import {
    billingVariants,
    mockDbVariants,
    pricingVariants,
    type MockDbOptions,
} from "../../../mock-api/mockDbVariants.ts";
import { getMockDbOptions, isMockApiEnabled, resetMockDb } from "../../../mock-api/msw/mswInit.ts";
import { cn } from "../../../utils/classNames.ts";
import { jsonLocalStorage } from "../../../utils/jotai/atomStorage.ts";
import { apikeyAtom } from "../../api/apikey.store.ts";
import { queryClientAtom } from "../../api/queryClient.ts";
import { clearAllToasts } from "../../notifications/toast.tsx";
import { debugBarVisibleAtom } from "./DebugMenu.store.ts";
import { MockDbEditor } from "./MockDb.tsx";
import { SiteThemeSelect } from "./SiteThemeSelect.tsx";

//#region Debug bar

export function DebugBar() {
    const visible = useAtomValue(debugBarVisibleAtom);

    if (!visible) {
        return null;
    }
    const mockEnabled = isMockApiEnabled();
    return (
        <div className={cn(C.DebugBar, "flex flex-col gap-0.5rem")}>
            <h3 className="flex text-muted items-center gap-0.5rem">
                Debug <ResetButton />
            </h3>

            <Suspense>
                <ProviderChoice />
                <Heading>Mock DB {!mockEnabled && " - Disabled"}</Heading>
                {mockEnabled && (
                    <div className="flex flex-col gap-0.5rem">
                        <div className="flex flex-row gap-0.5rem">
                            <ResetMockDbButton />
                            <div className="grow">
                                <MockDbSelect />
                            </div>
                            <SingleLocationChoice />
                        </div>
                        <div className="flex flex-row gap-0.5rem children:grow">
                            <MockPricingSelect />
                            <MockBillingSelect />
                        </div>
                    </div>
                )}

                <Heading>I18N</Heading>
                <div className="flex flex-row gap-0.5rem children:grow">
                    <LocaleSelect />
                    <CurrencySelect />
                </div>

                {mockEnabled && (
                    <Suspense>
                        <Heading>DB editor</Heading>
                        <MockDbEditor />
                    </Suspense>
                )}

                <Heading>Config</Heading>

                <div className="HStack children:grow gap-2">
                    <div className="grow-0 px-2">
                        <MaskIcon className="i-lucide:brush size-0.875rem" />
                    </div>
                    <SiteThemeSelect />
                </div>
                <FirstAccountCreditChoice />
                <JsonTextArea field="referralCreditAmounts" />
                <JsonTextArea rows={3} field="limitedBillingAccountConfig" />
                <JsonTextArea rows={8} field="billingAccountFields" />
                <JsonTextArea rows={12} field="predefinedPackages" />
                {/* <JsonTextArea label="chatConfig" value={chatConfig} /> */}
                {/* <JsonTextArea label="chatSecrets" value={chatSecrets} /> */}

                <ToggleModuleChoice />

                <ChangeApiKey />
            </Suspense>
        </div>
    );
}

export default DebugBar;

function Heading({ children }: PropsWithChildren) {
    return <div className={`text-muted b-b b-b-solid b-gray-3 p-b-0.2em ${C.UnPad}`}>{children}</div>;
}

//#endregion

//#region Mock DB

// Cache the last set value since we do not have a subscription to receive options updates from the mock DB
const cachedOptionsAtom = atom<MockDbOptions | undefined>(undefined);

const mockDbOptionsAtom = atom(
    async (get) => {
        console.debug("mockDbOptionsAtom get");
        return get(cachedOptionsAtom) ?? (await getMockDbOptions());
    },
    async (get, set, options: MockDbOptions) => {
        console.debug("mockDbOptionsAtom set", options);
        await resetMockDb(options);
        set(cachedOptionsAtom, options);

        // NB: Invalidate all client caches (since the mock DB was re-built)
        clearAllToasts();
        // TODO: should also reset all error boundaries
        await get(queryClientAtom).resetQueries({ type: "all" });
    },
);

function ResetMockDbButton() {
    const setOptions = useSetAtom(mockDbOptionsAtom);

    return (
        <WTooltipButton
            variant="ghost"
            icon="i-lucide:trash"
            action={async () => await setOptions({})}
            title="Reset mock DB"
        />
    );
}

function MockDbSelect() {
    return <MockVariantSelect title="DB variant" variants={mockDbVariants} field="variant" />;
}

function MockPricingSelect() {
    return <MockVariantSelect title="Pricing variant" variants={pricingVariants} field="pricingVariant" />;
}

function MockBillingSelect() {
    return <MockVariantSelect title="Billing variant" variants={billingVariants} field="billingVariant" />;
}

function MockVariantSelect({
    title,
    variants,
    field,
}: {
    title: string;
    variants: Record<string, string>;
    field: "variant" | "pricingVariant" | "billingVariant" | "hostPools";
}) {
    const [options, setOptions] = useAtom(mockDbOptionsAtom);

    const currentValue = options[field];

    const items = Object.entries(variants).map(([value, label]) => ({ value, label }));

    return (
        <WSimpleSelect
            placeholder={title}
            value={currentValue ?? null}
            items={items}
            onChange={async (value) => {
                await setOptions({ ...options, [field]: value });
            }}
        />
    );
}

//#endregion

function SingleLocationChoice() {
    const [options, setOptions] = useAtom(mockDbOptionsAtom);

    return (
        <SimpleSwitch
            value={options.singleLocation}
            onChange={async (singleLocation) => await setOptions({ ...options, singleLocation })}
        >
            Single
            <br />
            location
        </SimpleSwitch>
    );
}

function Labeled({ label, children }: PropsWithChildren<{ label: ReactNode }>) {
    return (
        <div className="flex flex-row items-center gap-2">
            <span>{label}</span>
            {children}
        </div>
    );
}

function ProviderChoice() {
    const [provider, setProvider] = useAtom<MockProvider>(mockProviderAtom);

    const items = entries(allMockConfigs).map(([value, config]) => ({
        value,
        label: `${config.siteName} [${value}]`,
    }));

    return (
        <Labeled label="Provider">
            <WSimpleSelect className="flex-grow" value={provider} items={items} onChange={setProvider} />
        </Labeled>
    );
}

function FirstAccountCreditChoice() {
    const { firstAccountCredit } = useConfig();
    const updateConfig = useSetAtom(updateConfigOverrideAtom);
    const [value, setValue] = useState<number>(firstAccountCredit);

    function onInput(event: FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const value = parseFloat(target.value);

        setValue(value);
        updateConfig((config) => {
            config.firstAccountCredit = value;
        });
    }

    return (
        <div>
            <NumberField className={TF.NumberField} minValue={0} value={value} onInput={onInput}>
                <Label className={TF.Label}>firstAccountCredit</Label>
                <Input className={TF.Input} />
            </NumberField>
        </div>
    );
}

interface JsonTextAreaProps {
    field: keyof SiteConfig;
    rows?: number;
}

function JsonTextArea({ field, rows = 6 }: JsonTextAreaProps) {
    const config = useConfig();
    const updateConfig = useSetAtom(updateConfigOverrideAtom);
    const configValue = config[field];

    const prettyText = useMemo(() => stringify(configValue, { indent: 2, maxLength: 60 }), [configValue]);

    const [text, setText] = useState<string | undefined>(undefined);

    // Keep updating the value based on the config until the user changes it
    const value = text ?? prettyText;

    function changeText(text: string) {
        setText(text);

        updateConfig((config) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            config[field] = JSON.parse(text) as any;
        });
    }

    return (
        <div className={C.Textarea}>
            <TextField className={TF.TextField} value={value} onChange={changeText}>
                <Label>{field}</Label>
                <TextArea className={TF.TextArea} rows={rows} />
            </TextField>
        </div>
    );
}

function ToggleModuleChoice() {
    const [config, updateConfig] = useAtom(updateConfigOverrideAtom);

    const items: (keyof SiteConfig)[] = [
        "s3ForcePathStyle",
        "vmAppsEnabled",
        "privateNetworksEnabled",
        "loadBalancerEnabled",
        "storageEnabled",
        "servicesEnabled",
        "kubernetesEnabled",
        "bareMetalEnabled",
        "chatEnabled",
        "recurringPaymentsEnabled",
        "referralCodeEnabled",
        "allowDebt",
    ];

    return (
        <div className={C.FlexList}>
            {items.map((item) => (
                <SimpleSwitch
                    key={item}
                    value={config[item] as unknown as boolean}
                    onChange={(value) =>
                        updateConfig((config) => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            config[item] = value as any;
                        })
                    }
                >
                    {item}
                </SimpleSwitch>
            ))}
        </div>
    );
}

function CurrencySelect() {
    const [config, updateConfig] = useAtom(updateConfigOverrideAtom);

    const currencies = [undefined, "USD", "EUR", "JPY", "IDR", "THB", "ZAR", "TND"].map(toSimpleItem);

    return (
        <WSimpleSelect
            valueClassName="min-w-5em"
            placeholder="Currency"
            value={config.siteCurrency}
            items={currencies}
            onChange={(value) =>
                updateConfig((config) => {
                    config.siteCurrency = value;
                })
            }
        />
    );
}

function LocaleSelect() {
    const [config, updateConfig] = useAtom(updateConfigOverrideAtom);

    const locales = [undefined, ...availableLocales].map(toSimpleItem);

    return (
        <WSimpleSelect
            valueClassName="min-w-5em"
            placeholder="Locale"
            value={config.siteLocale}
            items={locales}
            onChange={(value) =>
                updateConfig((config) => {
                    config.siteLocale = value;
                })
            }
        />
    );
}

function ResetButton() {
    const resetConfig = useSetAtom(resetConfigOverridesAtom);
    return (
        <WTooltipButton
            variant="ghost"
            icon="i-lucide:list-restart"
            action={resetConfig}
            title={resetConfigOverridesTitle}
        />
    );
}

function ChangeApiKey() {
    const setApikey = useSetAtom(apikeyAtom);
    const apikey = String(jsonLocalStorage().getItem("last-api-key", ""));
    const [value, setValue] = useState<string>("");

    function changeApiKey() {
        setApikey(value);
    }

    function revertApiKey() {
        setApikey(apikey);
    }

    function onInput(event: FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        setValue(value);
    }

    return (
        <>
            <div>Set/Reset apikey</div>
            <FieldButtonGroup>
                <TextField className={TF.TextField} value={value} onInput={onInput}>
                    <Input className={TF.Input} />
                </TextField>
                <WButton color="primary" isDisabled={!value} action={changeApiKey}>
                    Set
                </WButton>
                <WButton color="default" action={revertApiKey}>
                    Reset
                </WButton>
            </FieldButtonGroup>
        </>
    );
}
