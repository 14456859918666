import TF from "../../../components/forms/TextField.module.css";
import C from "./DebugMenu.module.css";

import { useQueryClient } from "@tanstack/react-query";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useEffect, useState } from "react";
import { Label, TextArea, TextField } from "react-aria-components";
import { useDebounceCallback } from "usehooks-ts";
import { getMockDb, markDirty } from "../../../mock-api/db.ts";
import type { MockDb } from "../../../mock-api/defaultDb.ts";
import * as mswInit from "../../../mock-api/msw/mswInit.ts";
import * as vmParametersQuery from "../../compute/vmParametersQuery.ts";
import * as pricingQuery from "../../pricing/query.ts";

// TODO: Use an async atom instead (to manage mock DB state and readiness)
function useMswReady() {
    const [isWaiting, setIsWaiting] = useState(false);
    const [isReady, setReady] = useState(false);
    useEffect(() => {
        if (!isWaiting) {
            discardPromise(mswInit.waitForMswInit().then(() => setReady(true)));
            setIsWaiting(true);
        }
    }, [isWaiting]);
    return isReady;
}

export function MockDbEditor() {
    const queryClient = useQueryClient();

    const isReady = useMswReady();
    if (!isReady) {
        return null;
    }

    return (
        <>
            <DbJsonEditor
                label="VM parameters"
                getValue={(db) => db.vmParameters}
                setValue={(db, value) => {
                    db.vmParameters = value;
                }}
                extraCallback={() => {
                    discardPromise(queryClient.invalidateQueries({ queryKey: vmParametersQuery.queryKey }));
                }}
            />
            <DbJsonEditor
                label="Pricing list"
                getValue={(db) => db.pricing}
                setValue={(db, value) => {
                    db.pricing = value;
                }}
                extraCallback={() => {
                    discardPromise(queryClient.invalidateQueries({ queryKey: pricingQuery.queryKey }));
                }}
            />
        </>
    );
}

function DbJsonEditor<T>({
    label,
    getValue,
    setValue,
    extraCallback,
}: {
    label: string;
    getValue: (db: MockDb) => T;
    setValue: (db: MockDb, value: T) => void;
    extraCallback?: () => void;
}) {
    const changeText = useDebounceCallback(function changeText(value: string) {
        const obj = JSON.parse(value) as T;

        setValue(getMockDb(), obj);
        markDirty();

        if (extraCallback) {
            extraCallback();
        }
    }, 500);

    const text = JSON.stringify(getValue(getMockDb()), null, 2);

    return (
        <div className={C.Textarea}>
            <TextField className={TF.TextField} defaultValue={text} onChange={changeText}>
                <Label className={TF.Label}>{label}</Label>
                <TextArea className={TF.TextArea} rows={20} />
            </TextField>
        </div>
    );
}
