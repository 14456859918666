import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { ReactNode } from "react";
import { useLabel } from "react-aria";
import { cn } from "../../utils/classNames.ts";

/** Checkbox with custom `undefined` (indeterminate) state */
export function SimpleSwitch({
    value,
    onChange,
    children,
    className = "font-size-small",
}: {
    className?: string;
    value: boolean | undefined;
    onChange: (value: boolean | undefined) => void | Promise<void>;
    children?: ReactNode;
}) {
    const { labelProps, fieldProps } = useLabel({ label: true });

    const values = [false, true, undefined];
    function nextValue(value: boolean | undefined) {
        return values[(values.indexOf(value) + 1) % values.length];
    }

    function changeToNextValue() {
        discardPromise(onChange(nextValue(value)));
    }

    const size = "size-1rem";
    return (
        <div
            className="HStack gap-1 cursor-pointer"
            onClick={(e) => {
                e.stopPropagation();
                changeToNextValue();
            }}
        >
            {value === undefined ? (
                <div
                    className={cn(
                        size,
                        "b b-1 rounded-sm b-gray-5 bg-gray-3 flex content-center justify-center text-muted text-xs",
                    )}
                >
                    ?
                </div>
            ) : (
                <input
                    type="checkbox"
                    className={cn(size)}
                    checked={value}
                    onChange={changeToNextValue}
                    {...fieldProps}
                />
            )}
            {/* biome-ignore lint/a11y/noLabelWithoutControl: Connected via `useLabel()` */}
            <label className={cn("cursor-pointer", className)} {...labelProps}>
                {children}
            </label>
        </div>
    );
}
